// TODO: add Typescript Typings
import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";

import TeaserBig from "../components/2-molecules/TeaserBig/TeaserBig";
import {
  getInternalLinkByReference,
  getRootUri,
  isExternalUri,
} from "../helper/uri";

const ContentfulTeaserBig = ({ content }) => {
  const titleTag = _get(content, "headlineTag", undefined);

  const imageWithFormat =
    _get(content, "imageFormat", undefined) === "4:3"
      ? "image4to3.image.gatsbyImageData"
      : "image.image.gatsbyImageData";

  // Contentful content link
  const link = _get(content, "link", undefined);
  const linkInternal = getInternalLinkByReference(link);
  // manual link for internal/external links with internal override
  const linkLabel = _get(content, "linkLabel", undefined);
  const linkTarget =
    typeof linkInternal === "string" && linkInternal.length
      ? linkInternal
      : _get(content, "linkTarget", undefined);
  const linkTargetIsExternal = isExternalUri(linkTarget);

  return (
    <TeaserBig
      id={_get(content, "idSlug")}
      image={
        <GatsbyImage
          image={_get(content, imageWithFormat, undefined)}
          alt={_get(content, "image.title", undefined)}
        />
      }
      headline={_get(content, "title", undefined)}
      headlineTag={
        typeof titleTag === "string" ? titleTag.toLowerCase() : undefined
      }
      teaserPosition={_get(content, "position", undefined)}
      theme={_get(content, "theme", undefined) || "default"}
      button={
        linkTargetIsExternal ? (
          <a
            href={linkTarget}
            rel="nofollow noreferrer noopener"
            target="_blank"
          >
            {linkLabel}
          </a>
        ) : (
          <Link to={getRootUri(linkTarget)}>{linkLabel}</Link>
        )
      }
      buttonVariant={_get(content, "buttonVariant", undefined) || "primary"}
    >
      {_get(content, "body.body", undefined)}
    </TeaserBig>
  );
};

export default ContentfulTeaserBig;

export const query = graphql`
  fragment FragmentContentfulTeaserBig on ContentfulComponentTeaserBig {
    # base
    internal {
      type
    }
    idSlug
    # rest
    body {
      body
    }
    imageFormat
    image {
      title
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          placeholder: BLURRED
          # aspectRatio: 1.33
          aspectRatio: 1.5
        )
      }
    }
    image4to3: image {
      title
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          placeholder: BLURRED
          aspectRatio: 1.33
          # aspectRatio: 1.5
        )
      }
    }
    linkTarget
    linkLabel
    link {
      ... on ContentfulBlogPost {
        id
        slug
      }
      ... on ContentfulCategoryPage {
        id
        slug
      }
    }
    position
    theme
    buttonVariant
    title
    titleTag
  }
`;
