import React, { forwardRef, isValidElement } from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";

import { Button } from "../../1-atoms/Button/Button";
import Clone from "../../0-electrons/Clone/Clone";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";

import * as css from "./Card.module.scss";

interface CardChildrenProps {
  /** The Button to use */
  button?: React.ReactNode | undefined;
  /** The Cards children */
  children?: React.ReactNode;
  /** The Cards Headline */
  headline?: React.ReactNode;
  /** The Cards kicker */
  kicker?: React.ReactNode;
  /** The Cards image */
  slot?: React.ReactElement;
  /** The Cards theme */
  theme?: "default" | "primary" | "skin";
}

export interface CardProps extends CardChildrenProps {
  /** className to add */
  className?: string | undefined;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  function CardComponent(
    { button, children, className, headline, kicker, slot, theme = "default" },
    forwardRef
  ) {
    /**
     * Theming
     */
    const themeSettings = {
      default: {
        base: classNames(getClassName(css, "Card"), className),
        content: getClassName(css, "Card__content"),
        kickerColor: "blue",
        headlineColor: "grey",
        copyColor: "grey",
        cta: <Button node={button} />,
        ctaClassName: getClassName(css, "Card__cta"),
      },
      primary: {
        base: classNames(
          getClassName(css, "Card"),
          getClassName(css, "Card--theme-primary"),
          className
        ),
        content: getClassName(css, "Card__content"),
        kickerColor: "white",
        headlineColor: "white",
        copyColor: "white",
        cta: <Button node={button} type="secondary" invert />,
        ctaClassName: classNames(
          getClassName(css, "Card__cta"),
          getClassName(css, "Card__cta--auto-width")
        ),
      },
      skin: {
        base: classNames(
          getClassName(css, "Card"),
          getClassName(css, "Card--theme-skin"),
          className
        ),
        content: getClassName(css, "Card__content"),
        kickerColor: "white",
        headlineColor: "white",
        copyColor: "white",
        cta: <Button node={button} type="primary" invert />,
        ctaClassName: classNames(
          getClassName(css, "Card__cta"),
          getClassName(css, "Card__cta--auto-width")
        ),
      },
    };
    const themeWithFallback = theme || "default";

    return (
      <div className={themeSettings[themeWithFallback].base} ref={forwardRef}>
        {/* Image Slot */}
        {isValidElement(slot) ? (
          <Clone node={slot} className={getClassName(css, "Card__image")} />
        ) : null}

        {/* Card Content */}
        <div className={themeSettings[themeWithFallback].content}>
          {kicker ? (
            <TypographyCopy
              className={getClassName(css, "Card__category")}
              color={themeSettings[themeWithFallback].kickerColor}
            >
              {kicker}
            </TypographyCopy>
          ) : null}
          <TypographyHeadline
            className={getClassName(css, "Card__headline")}
            color={themeSettings[themeWithFallback].headlineColor}
            size="h4"
            tag="h3"
          >
            {headline}
          </TypographyHeadline>

          {typeof children === "string" ? (
            <TypographyCopy
              className={getClassName(css, "Card__copy")}
              color={themeSettings[themeWithFallback].copyColor}
            >
              {children}
            </TypographyCopy>
          ) : null}

          {React.isValidElement(button) ? (
            <Clone
              node={themeSettings[themeWithFallback].cta}
              className={themeSettings[themeWithFallback].ctaClassName}
            />
          ) : null}
        </div>
      </div>
    );
  }
);

export default Card;
