import React from "react";

import { getClassName } from "../../0-electrons/css";
import Teaser, { TeaserProps } from "../../2-molecules/Teaser/Teaser";

import DynamicTag from "../../0-electrons/DynamicTag/index";

import * as css from "./TeaserGroup.module.scss";

export interface TeaserGroupProps {
  /** The Teasers children */
  content: Array<TeaserProps>;
  /** Optional headline */
  headline?: string;
  /** Tag to use for optional headline */
  headlineTag?: keyof JSX.IntrinsicElements;
  /** The HTML id attribute to add */
  id?: string;
}

export const TeaserGroup: React.FC<TeaserGroupProps> = ({
  content,
  headline,
  headlineTag = "h2",
  id,
}: TeaserGroupProps) => {
  const TeaserItems = content.map((item, i) => (
    <Teaser
      key={i}
      image={item.image}
      number={item.number}
      headline={item.headline}
      headlineTag={item.headlineTag}
      textLink={item.textLink}
      href={item.href}
      className={getClassName(css, "teaserGroup__item")}
    >
      {item.children}
    </Teaser>
  ));

  return (
    <section
      className={getClassName(css, "teaserGroup")}
      id={typeof id === "string" ? id : undefined}
    >
      {typeof headline === "string" && headline.length > 0 ? (
        <DynamicTag
          tag={headlineTag}
          className={getClassName(css, "teaserGroup__headline")}
        >
          {headline}
        </DynamicTag>
      ) : null}

      {TeaserItems}
    </section>
  );
};

export default TeaserGroup;
