// extracted by mini-css-extract-plugin
export var Slider = "Slider-module--Slider--4eUVs";
export var SliderCardSizeS = "Slider-module--Slider--card-size-s--ygxWn";
export var SliderDisableScroll = "Slider-module--Slider--disable-scroll--6SmeI";
export var SliderSnapMandatory = "Slider-module--Slider--snap-mandatory--dBaTv";
export var SliderThemeSkin = "Slider-module--Slider--theme-skin--4MKLi";
export var SliderThemeWhite = "Slider-module--Slider--theme-white--NbCy7";
export var Slider__button = "Slider-module--Slider__button--k1V5Q";
export var Slider__buttonHide = "Slider-module--Slider__button--hide--Ocbcn";
export var Slider__buttonShow = "Slider-module--Slider__button--show--mSuz9";
export var Slider__card = "Slider-module--Slider__card--bJtb4";
export var Slider__nav = "Slider-module--Slider__nav--F5hu7";
export var Slider__scrollfix = "Slider-module--Slider__scrollfix--8Fu+t";
export var Slider__track = "Slider-module--Slider__track--FIbJI";