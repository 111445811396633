import React from "react";

export interface DynamicTagProps {
  /** The Teasers children */
  children?: React.ReactNode;
  /** The Teasers children */

  className?: string;
  tag?: keyof JSX.IntrinsicElements; // tag?: HeadlineTag;
}

const DynamicTag: React.FC<DynamicTagProps> = ({
  children,
  className,
  tag: Tag = "p",
}: DynamicTagProps) => {
  return (
    <Tag
      className={
        typeof className === "string" && className.length > 0
          ? className
          : undefined
      }
    >
      {children}
    </Tag>
  );
};

export default DynamicTag;
