import React, { useState } from "react";
import classNames from "classnames";
import { default as _has } from "lodash/has";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";
import Button from "../../1-atoms/Button/Button";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";
import VideoPlayer, {
  VideoPlayerStatesType,
} from "../../1-atoms/VideoPlayer/VideoPlayer";

import * as css from "./Stage.module.scss";

export interface StageProps {
  /** The Button to use */
  button?: React.ReactNode | undefined;
  /** The Stages Children */
  children?: React.ReactNode;
  /** The Stages Headline */
  headline?: React.ReactNode;
  /** The Stages CTA */
  cta?: React.ReactNode;
  /** The Stages claim */
  claim?: React.ReactElement;
  /** The Stages image */
  image: React.ReactElement;
}

export const Stage: React.FC<StageProps> = ({
  cta,
  children,
  image,
  claim,
  headline,
}: StageProps) => {
  // TODO: cleanup DOM/HTML structure
  // should not be dependent on cta used or not used
  return (
    <section className={getClassName(css, "Stage")}>
      <Clone node={image} className={getClassName(css, "Stage__image")} />
      {cta ? (
        <div className={getClassName(css, "Stage__homepageContainer")}>
          <TypographyHeadline
            color="grey"
            size="h1"
            className={getClassName(css, "Stage__heading")}
          >
            {headline}
          </TypographyHeadline>
          <TypographyCopy
            color="grey"
            size="big"
            className={getClassName(css, "Stage__copy")}
          >
            {children}
          </TypographyCopy>
          <Button tag="button" type="primary" icon="playCircle">
            {cta}
          </Button>
        </div>
      ) : (
        <div className={getClassName(css, "Stage__container")}>
          {claim ? (
            <Clone node={claim} className={getClassName(css, "Stage__claim")} />
          ) : null}

          <div className={getClassName(css, "Stage__textContainer")}>
            <TypographyHeadline
              color="white"
              className={getClassName(css, "Stage__headline")}
            >
              {headline}
            </TypographyHeadline>
          </div>
        </div>
      )}
    </section>
  );
};

export default Stage;

export interface StageWithVideoProps {
  src: string;
  poster?: string;
  title?: string;
  titleTag?: string;
  subline?: string;
  cta?: string;
  onClick?: () => void | null;
}

export const StageWithVideo: React.FC<StageWithVideoProps> = ({
  src,
  poster,
  title,
  titleTag = "h2",
  subline,
  cta = "Jetzt abspielen",
  onClick,
}: StageWithVideoProps) => {
  const [player, setPlayer] = useState(null);
  const [playerState, setPlayerState] =
    useState<VideoPlayerStatesType>("loading");
  const [useFacade, setUseFacade] = useState(true);
  const hideControls =
    playerState === "playing" ||
    playerState === "paused" ||
    playerState === "ended";

  const playerAvailable = _has(player, "play");

  return (
    <section className={getClassName(css, "Stage")}>
      <VideoPlayer
        className={getClassName(css, "Stage__video")}
        autoplay
        controls
        facade={useFacade}
        onReady={plyr => {
          setPlayer(plyr);
        }}
        onStateChange={state => {
          setPlayerState(state);
        }}
        poster={poster}
        src={src}
      />
      {cta ? (
        <div
          className={classNames(getClassName(css, "Stage__homepageContainer"), {
            [getClassName(css, "Stage__homepageContainer--hidden") as string]:
              hideControls,
          })}
        >
          <TypographyHeadline
            className={getClassName(css, "Stage__heading")}
            color="grey"
            disableSpacing
            size="h1"
            tag={titleTag}
          >
            {title}
          </TypographyHeadline>
          <TypographyCopy
            color="grey"
            size="big"
            className={getClassName(css, "Stage__copy")}
          >
            {subline}
          </TypographyCopy>
          <Button
            tag="button"
            type="primary"
            icon="playCircle"
            onClick={() => {
              if (typeof onClick === "function") {
                onClick();
              }

              if (playerAvailable) {
                if (playerState === "playing") {
                  player.pause();
                  setPlayerState("pause");
                } else {
                  player.play();
                  setPlayerState("playing");
                }
              } else {
                setUseFacade(false);
              }
            }}
          >
            {cta}
          </Button>
        </div>
      ) : null}
    </section>
  );
};
