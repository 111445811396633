import { default as _has } from "lodash/has";

const DEBUG = false;

function debugLog() {
  if (
    DEBUG === true ||
    (typeof window === "object" && window.DEBUG_TRACKING === true)
  ) {
    console.log(...arguments);
  }
}

const googleAnalyticsAvailable = () => {
  const gaAvailable =
    typeof window === "object" && typeof window.gtag === "function";

  debugLog("GA available", gaAvailable);
  return gaAvailable;
};

const googleAnalyticsEvent = (event, info) => {
  debugLog("GA Tracking Event: event", event);
  if (googleAnalyticsAvailable()) {
    const gaTracker = window.gtag;
    gaTracker("event", event, info);
  }
};

const tracking = {
  playVideo: () => {
    debugLog("Tracking playVideo");
    googleAnalyticsEvent("click", {
      eventCategory: "video",
      eventAction: "play",
      eventLabel: "#hautstark21",
    });
  },
};

const gtmDataLayerPush = event => {
  if (typeof window === "undefined") return;

  if (!_has(window, "dataLayer") || !Array.isArray(window.dataLayer)) {
    debugLog("create dataLayer");
    window.dataLayer = [];
  }

  const gtmDataLayer = window.dataLayer;

  gtmDataLayer.push(event);
  debugLog("gtm datalayer push", event);
};

export { googleAnalyticsEvent, gtmDataLayerPush, tracking };
