// TODO: add Typescript Typings
import React from "react";
import { graphql } from "gatsby";

import { default as _get } from "lodash/get";

import {
  Video,
  //   VideoProps
} from "../components/2-molecules/Video/Video";

import { tracking } from "../tracking/tracking";

interface ContentfulStageVideoContentProps {
  id: string;
  idSlug?: string;
  title?: string;
  titleTag?: string;
  subtitle?: string;
  video: string;
  poster?: string;
  onClick?: () => void | null;
}

export interface ContentfulStageVideoProps {
  content: ContentfulStageVideoContentProps;
}

const ContentfulStageVideo: React.FC<ContentfulStageVideoProps> = ({
  content,
}: ContentfulStageVideoProps) => {
  //   const title = _get(content, "title", undefined);
  //   const titleTag = _get(content, "titleTag", undefined);
  //   const subtitle = _get(content, "subtitle", undefined);
  const poster = _get(content, "poster.file.url", null);
  const caption = _get(content, "caption", null);
  const video = _get(content, "video");

  return (
    <Video
      //   title={title}
      //   titleTag={titleTag}
      //   subline={subtitle}
      poster={poster}
      src={video}
      caption={caption}
      onClick={() => tracking.playVideo()}
    />
  );
};

export default ContentfulStageVideo;

export const query = graphql`
  fragment FragmentContentfulVideo on ContentfulComponentVideoContent {
    # id
    # idSlug
    # title
    # titleTag
    # subtitle
    video
    caption
    poster {
      file {
        url
      }
    }
    internal {
      type
    }
  }
`;
