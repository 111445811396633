import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { default as _get } from "lodash/get";

import { getClassName } from "../../0-electrons/css";

import * as css from "./VideoPlayer.module.scss";
import "plyr/src/sass/plyr.scss";

export type VideoPlayerStatesType =
  | "loading"
  | "ready"
  | "playing"
  | "paused"
  | "ended";
export interface VideoPlayerProps {
  /** Autoplay or not: won't be used in safari. Should be used when using a facade. */
  autoplay?: boolean;
  /** Class Name to add */
  className?: string;
  /** Show controls */
  controls?: boolean;
  /** If a facade with a poster should be used. Won't be used on Safari as the autoplay blocking of Safari prevents video play when disabling the facade  */
  facade?: boolean;
  /** Event: onPlayerInit */
  onReady?: (plyrInstance: any) => void;
  /** Event: onPlayerInit */
  onStateChange?: (state: VideoPlayerStatesType) => void;
  /** The VideoPlayers thumbnail */
  poster?: string;
  /** The VideoPlayers src */
  src: string;
}

const plyrSettings = {
  youtube: {
    // see @url https://github.com/sampotts/plyr#initialising
    // and @url https://developers.google.com/youtube/player_parameters
    origin: process.env.GATSBY_SITE_URL,
    enablejsapi: 1,
    iv_load_policy: 3,
    modestbranding: 1,
    noCookie: true,
    rel: 0,
    showinfo: 0,
  },
};

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  className,
  autoplay = false,
  controls = false,
  facade = false,
  onReady,
  onStateChange,
  poster,
  src,
}: VideoPlayerProps) => {
  const isSafari =
    typeof navigator === "object" &&
    Array.isArray(navigator.vendor.match(/apple/i));
  const useAutoplay = isSafari ? false : autoplay;
  const useFacade = isSafari ? false : facade;

  const [videoPlayer, setVideoPlayer] = useState({
    init: false,
    instance: null,
  });
  const setPlayerState = (state: VideoPlayerStatesType) => {
    if (typeof onStateChange === "function") {
      onStateChange(state);
    }
  };
  const srcNoCookie = youTubeNoCookieEmbed(src);
  const plyrRef = useRef(null);
  const hasPoster = typeof poster === "string" && poster.length > 0;

  useEffect(() => {
    // bail early if ref is not set
    if (plyrRef.current === null) return;

    // console.log("plyrRef", plyrRef);
    /**
     * First render cylce: init the video player
     */
    if (videoPlayer.init === false) {
      // console.log("init and load plyr");
      import(
        "plyr" /* webpackChunkName: "component---components-atoms-videoplayer-js-plyr" */
      ).then(component => {
        const Plyr = component.default;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const plyrInstance = new Plyr(plyrRef.current!, {
          autoplay: useAutoplay,
          controls:
            controls === true
              ? [
                  "play-large",
                  "play",
                  "progress",
                  "current-time",
                  "mute",
                  "volume",
                  "captions",
                  "settings",
                  "pip",
                  "airplay",
                  "fullscreen",
                ]
              : [],
          youtube: plyrSettings.youtube,
        });

        /**
         * We have to rely on the players events before setting the plyr instance
         * Otherwise the player is not ready yet, before react fires the next
         * render cycle
         */
        plyrInstance.on("ready", event => {
          // console.log("plyr ready", event, plyrInstance.embed.getPlayerState());

          if (typeof onReady === "function") onReady(plyrInstance);

          setPlayerState("ready");

          setVideoPlayer({
            init: true,
            instance: plyrInstance,
          });
        });

        plyrInstance.on("play", () => {
          setPlayerState("playing");
        });

        plyrInstance.on("pause", () => {
          setPlayerState("paused");
        });

        plyrInstance.on("ended", () => {
          setPlayerState("ended");
        });
      });
    }
  }, [videoPlayer.init, facade]);

  return useFacade ? (
    <div
      key="facade"
      className={classNames(
        getClassName(css, "VideoPlayer"),
        getClassName(css, "VideoPlayer--facade"),
        {
          [className as string]:
            typeof className === "string" && className.length > 0,
        }
      )}
    >
      <div
        key="facade-plyr"
        className={classNames("plyr", "plyr--video", {
          "plyr__poster-enabled": hasPoster,
        })}
      >
        <div
          key="facade-embed"
          className="plyr__video-wrapper plyr__video-embed"
          style={{ aspectRatio: "16 / 9" }}
        >
          {hasPoster ? (
            <div
              key="facade-poster"
              className="plyr__poster"
              style={{ backgroundImage: `url(${poster})` }}
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <div
      key="player"
      className={classNames(getClassName(css, "VideoPlayer"), {
        [className as string]:
          typeof className === "string" && className.length > 0,
        [getClassName(css, "VideoPlayer--ready") as string]: videoPlayer.init,
      })}
    >
      <div key="player-embed" ref={plyrRef} className="plyr__video-embed">
        <Embed src={srcNoCookie} poster={poster} autoplay={useAutoplay} />
      </div>
    </div>
  );
};

const Embed = ({
  key,
  poster,
  autoplay,
  src,
}: {
  src: string;
  autoplay?: boolean;
  key?: string;
  poster?: string;
}) => (
  <iframe
    key={key}
    src={src}
    allowFullScreen={true}
    // @ts-expect-error "Property 'allowtransparency' does not exist on type 'DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>'. Did you mean 'allowTransparency'?ts(2322)"
    // eslint-disable-next-line react/no-unknown-property
    allowtransparency="true"
    // allow="autoplay"
    allow={autoplay ? "autoplay" : undefined}
    poster={poster}
  ></iframe>
);

/**
 * Change YouTube URL to nocookie embed URL
 *
 * @param {string} youtubeUrl
 *
 * @return {string} Adjusted YouTube URL if it has been a YouTube URL, fallsback to youtubeUrl
 */
function youTubeNoCookieEmbed(youtubeUrl: string) {
  /**
   * Common YouTube URLs are and sometimes without www
   * https://youtu.be/bTqVqk7FSmY
   * https://www.youtube.com/embed/bTqVqk7FSmY
   * https://www.youtube.com/video/bTqVqk7FSmY
   * https://www.youtube.com/watch?v=bTqVqk7FSmY
   *
   * What we want is:
   * https://www.youtube-nocookie.com/embed/bTqVqk7FSmY
   */
  const youtubeIdRegex =
    typeof youtubeUrl === "string" && youtubeUrl.length > 0
      ? youtubeUrl.match(
          /(youtu\.be|youtube.*?)(^|\/|v=)(?<youtubeid>[a-z0-9_-]{11})(.*)?/im
        )
      : undefined;
  const youTubeId = _get(youtubeIdRegex, "groups.youtubeid", false);

  // Got the YouTube video ID, return nocookie embed url
  if (typeof youTubeId === "string")
    return `https://www.youtube-nocookie.com/embed/${youTubeId}`;

  // else: fall back to input string
  return youtubeUrl;
}

export default VideoPlayer;
