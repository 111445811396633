// TODO: add Typescript Typings
// TODO: support headline and so on
// TODO: refactor
import React from "react";

import { graphql, Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";

import Card from "../components/2-molecules/Card/Card";
import type {
  SliderTheme,
  TCardSize,
} from "../components/2-molecules/Slider/Slider";
import Slider from "../components/2-molecules/Slider/Slider";

import { getRootUri, isExternalUri } from "../helper/uri";

function Link({ href, ...props }: { href: string; [key: string]: any }) {
  if (isExternalUri(href))
    return (
      <a
        {...props}
        href={href}
        rel="nofollow noreferrer noopener"
        target="_blank"
      />
    );
  return (
    // @ts-ignore
    <GatsbyLink
      {...props}
      to={getRootUri(href)}
      data-gtm-internal-link="view_content"
    />
  );
}

type Item = any;

type Content = {
  cardSize?: TCardSize;
  theme?: SliderTheme;
  items: Array<Item>;
};

const ContentfulSliderCards: React.FC<{ content: Content }> = ({ content }) => {
  const theme = _get(content, "theme", undefined);
  const cardSize = _get(content, "cardSize", undefined);
  const cardTheme = _get(content, "cardTheme", undefined);

  const items = content.items.map((item, index) => {
    const imageData = _get(item, "image.gatsbyImageData", undefined);
    const imageAlt = _get(item, "image.title", undefined);
    const buttonLabel = _get(item, "buttonLabel", "Mehr erfahren");

    return {
      number: index + 1,
      kicker: _get(item, "kicker", undefined),
      headline: _get(item, "title", undefined),
      button: {
        label:
          typeof buttonLabel === "string" && buttonLabel.length > 0
            ? buttonLabel
            : "Mehr erfahren",
        href: _get(item, "buttonContentLink.slug", item?.buttonTextLink),
      },
      image: {
        data: imageData,
        alt: imageAlt,
      },
      copy: item?.copy,
    };
  });

  return (
    <Slider cardSize={cardSize} theme={theme}>
      {items.map((item, index) => {
        return (
          <Card
            key={index}
            theme={cardTheme}
            slot={<GatsbyImage image={item.image.data} alt={item.image.alt} />}
            kicker={item.kicker}
            headline={item.headline}
            button={<Link href={item.button.href}>{item.button.label}</Link>}
          >
            {item.copy}
          </Card>
        );
      })}
    </Slider>
  );
};

export default ContentfulSliderCards;

export const query = graphql`
  fragment FragmentContentfulSliderCards on ContentfulComponentCardSlider {
    internal {
      type
    }
    id
    idSlug
    theme
    cardSize
    cardTheme
    items {
      image {
        id
        title
        gatsbyImageData(aspectRatio: 1.33, width: 700)
      }
      kicker
      title
      buttonLabel
      buttonTextLink
      buttonContentLink {
        slug
      }
      copy
    }
  }
`;
