import React from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";

import Card, { CardProps } from "../../2-molecules/Card/Card";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";

import * as css from "./CardGroup.module.scss";

interface CardGroupChildrenProps {
  /** The CardGroups children */
  cards: Array<CardProps>;
  /** The CardGroups Headline */
  headline?: React.ReactNode;
  /** Set the CardsGroup background color */
  background?: boolean;
}

export interface CardGroupProps extends CardGroupChildrenProps {
  /** className to add */
  className?: string | undefined;
}

export const CardGroup: React.FC<CardGroupProps> = ({
  className,
  cards,
  headline,
  background = false,
}: CardGroupProps) => {
  const CardItem = cards.map(item => (
    <Card
      key={item.headline}
      kicker={item.kicker}
      headline={item.headline}
      button={item.button}
      slot={item.slot}
      className={getClassName(css, "CardGroup__card")}
    >
      {item.children}
    </Card>
  ));

  const gradient =
    background === true ? getClassName(css, "CardGroup--backgroundColor") : "";

  return (
    <div
      className={classNames(
        getClassName(css, "CardGroup"),
        className,
        gradient
      )}
    >
      <div className={getClassName(css, "CardGroup__content")}>
        {headline ? (
          <TypographyHeadline
            color={background ? "white" : "default"}
            className={getClassName(css, "CardGroup__headline")}
            tag="h2"
            size="h2"
            disableSpacing
          >
            {headline}
          </TypographyHeadline>
        ) : (
          ""
        )}
        <div className={getClassName(css, "CardGroup__cards")}>{CardItem}</div>
      </div>
    </div>
  );
};

export default CardGroup;
