import React from "react";
import classNames from "classnames";

import Clone from "../../0-electrons/Clone/Clone";
import Button from "../../1-atoms/Button/Button";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";

import { getClassName } from "../../0-electrons/css";
import * as css from "./TeaserBig.module.scss";

interface TeaserProps {
  /** The Button to use */
  button?: React.ReactNode | undefined;
  //**A button variant to add  */
  buttonVariant?: "primary" | "text";
  /** A className to add */
  className?: string;
  /** The Teasers children */
  children?: React.ReactNode;
  /** The Teasers Headline */
  headline?: React.ReactNode;
  /** The Teasers Headline Tag to Use*/
  headlineTag?: "h2" | "h3" | "h4";
  /** The Teasers Image */
  image: React.ReactNode;
  /** The Teasers kicker */
  kicker?: React.ReactNode;
  /** The Teasers theme */
  theme?: "default" | "dark" | "skin";
  /** The Teasers Image Position */
  teaserPosition?: "left" | "right";
}

const TeaserBig: React.FC<TeaserProps> = ({
  button,
  buttonVariant = "primary",
  children,
  className,
  headline,
  headlineTag = "h2",
  image = null,
  theme = "default",
  teaserPosition = "left",
}) => {
  const hasImage = React.isValidElement(image);

  const themeSettings = {
    default: {
      headline: "grey",
      copy: "grey",
      buttonInvert: false,
    },
    dark: {
      headline: "white",
      copy: "white",
      buttonInvert: true,
    },
    skin: {
      headline: "white",
      copy: "white",
      buttonInvert: true,
    },
  };

  return (
    <section
      className={classNames(
        getClassName(css, "TeaserBig"),
        teaserPosition === "left"
          ? getClassName(css, "TeaserBig--left")
          : getClassName(css, "TeaserBig--right"),
        {
          [getClassName(css, `TeaserBig--theme-${theme}`) as string]:
            theme !== "default",
          [className as string]:
            typeof className === "string" && className.length,
        }
      )}
    >
      {hasImage ? (
        <Clone node={image} className={getClassName(css, "TeaserBig__image")} />
      ) : null}
      <div className={getClassName(css, "TeaserBig__container")}>
        <TypographyHeadline
          size="h2"
          tag={headlineTag}
          color={themeSettings[theme].headline}
          className={getClassName(css, "TeaserBig__headline")}
          disableSpacing
        >
          {headline}
        </TypographyHeadline>
        <TypographyCopy
          color={themeSettings[theme].copy}
          className={getClassName(css, "TeaserBig__content")}
        >
          {children}
        </TypographyCopy>

        {React.isValidElement(button) ? (
          <Clone
            node={
              <Button
                type={buttonVariant}
                invert={themeSettings[theme].buttonInvert}
                node={button}
              />
            }
          />
        ) : null}
        {/* <Button href={href} type="primary">
          {textLink}
        </Button> */}
      </div>
    </section>
  );
};

export default TeaserBig;
