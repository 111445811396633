// TODO: add Typescript Typings
import React from "react";

import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";
import { default as _has } from "lodash/has";

import { getRootUri } from "../helper/uri";
import CardGroup from "../components/3-organisms/CardGroup/CardGroup";

const ContentfulBlogTeaser = ({ posts }) => {
  const postsParsed = posts.map(parseCardTeaserData);
  const cards = postsParsed.map(post => mapTeaserDataToCard(post));
  const cardCount = cards.length;

  return cardCount > 0 ? <CardGroup cards={cards} /> : null;
};

export function parseCardTeaserData(data) {
  const hasImageAlternative = _get(
    data,
    "heroImageAlt.image.gatsbyImageData",
    null
  );
  const imagePath = hasImageAlternative ? "heroImageAlt" : "heroImage";

  const image = _get(data, `${imagePath}.image.gatsbyImageData`, null);
  const imageAlt = _get(data, `${imagePath}.title`, "");
  const hasImage = _has(image, "images");

  return {
    title: _get(data, "title", null),
    category: _get(data, "categories[0]", {
      title: _get(data, "title", null),
      titleShort: _get(data, "titleShort", null),
    }), // linked category or fallback to short title
    intro: _get(data, "intro.intro", null),
    introExcerpt: _get(data, "intro.childMarkdownRemark.excerpt", null),
    image: hasImage
      ? {
          src: image,
          alt: imageAlt,
        }
      : false,
    slug: _get(data, "slug", null),
  };
}

export function mapTeaserDataToCard(data) {
  const image = _get(data, "image");

  return {
    headline: _get(data, "title", null),
    children: _get(data, "introExcerpt", null),
    kicker:
      _get(data, "category.titleShort", null) ||
      _get(data, "category.title", null),
    button: <Link to={getRootUri(_get(data, "slug", "/"))}>Mehr erfahren</Link>,
    slot:
      image !== false ? (
        <GatsbyImage image={image.src} alt={image.alt} />
      ) : null,
  };
}

export default ContentfulBlogTeaser;

export const query = graphql`
  fragment TeaserBlogPost on ContentfulBlogPost {
    id
    slug
    title
    intro {
      intro
      childMarkdownRemark {
        excerpt
      }
    }
    heroImage {
      id
      title
      image {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 16:9
          aspectRatio: 1.77
        )
      }
    }
    heroImageAlt {
      id
      title
      image {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 16:9
          aspectRatio: 1.77
        )
      }
    }
    categories {
      title
      titleShort
      slug
    }
  }
  fragment TeaserCategoryPage on ContentfulCategoryPage {
    id
    slug
    title
    titleShort
    intro {
      intro
      childMarkdownRemark {
        excerpt
      }
    }
    heroImage {
      id
      title
      image {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 16:9
          aspectRatio: 1.77
        )
      }
    }
    # heroImageAlt {
    #   id
    #   title
    #   image {
    #     gatsbyImageData(
    #       placeholder: BLURRED
    #       width: 700
    #       # 16:9
    #       aspectRatio: 1.77
    #     )
    #   }
    # }
  }
`;
