// TODO: add Typescript Typings
import React from "react";
import { graphql } from "gatsby";

import { default as _get } from "lodash/get";

import {
  StageWithVideo,
  StageWithVideoProps,
} from "../components/2-molecules/Stage/Stage";

import { tracking } from "../tracking/tracking";

interface ContentfulStageVideoContentProps {
  id: string;
  idSlug?: string;
  title?: string;
  titleTag?: string;
  subtitle?: string;
  video: string;
  poster?: string;
  onClick?: () => void | null;
}

export interface ContentfulStageVideoProps {
  content: ContentfulStageVideoContentProps;
}

const ContentfulStageVideo: React.FC<ContentfulStageVideoProps> = ({
  content,
}: ContentfulStageVideoProps) => {
  const title = _get(content, "title", undefined);
  const titleTag = _get(content, "titleTag", undefined);
  const subtitle = _get(content, "subtitle", undefined);
  const poster = _get(content, "poster.file.url", null);
  const video = _get(content, "video");

  return (
    <StageWithVideo
      title={title}
      titleTag={titleTag}
      subline={subtitle}
      poster={poster}
      src={video}
      onClick={() => tracking.playVideo()}
    />
  );
};

export default ContentfulStageVideo;

export const query = graphql`
  fragment FragmentContentfulStageVideo on ContentfulComponentStageVideo {
    id
    idSlug
    title
    titleTag
    subtitle
    video
    poster {
      file {
        url
      }
    }
    internal {
      type
    }
  }
`;
