import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import { Clone } from "../../0-electrons/Clone/Clone";

import * as css from "./ContentImage.module.scss";

interface ContentImageProps {
  /** The outer className to add */
  className?: string | undefined;
  /** The ContentImages children */
  children?: React.ReactElement;
  /** The ContentImages image */
  caption?: React.ReactNode;
}

export const ContentImage: React.FC<ContentImageProps> = ({
  className,
  children,
  caption,
}: ContentImageProps) => {
  return (
    <figure
      className={classNames(getClassName(css, "ContentImage"), {
        [className as string]:
          typeof className === "string" && className.length > 0,
      })}
    >
      <Clone node={children} />

      {typeof caption === "string" && caption.length > 0 ? (
        <figcaption className={getClassName(css, "ContentImage__caption")}>
          {caption}
        </figcaption>
      ) : null}
    </figure>
  );
};

interface ContentImageWrapperProps {
  /** The ContentImages children */
  children: React.ReactElement;
  /** remove margins to top or bottom */
  disableMarginBottom?: boolean;
  disableMarginTop?: boolean;
}
export const ContentImageWrapper: React.FC<ContentImageWrapperProps> = ({
  children,
  disableMarginBottom = false,
  disableMarginTop = false,
}: ContentImageWrapperProps) => {
  return (
    <div
      className={classNames(getClassName(css, "ContentImageWrapper"), {
        [getClassName(
          css,
          "ContentImageWrapper--disable-margin-bottom"
        ) as string]: disableMarginBottom,
        [getClassName(
          css,
          "ContentImageWrapper--disable-margin-top"
        ) as string]: disableMarginTop,
      })}
    >
      {children}
    </div>
  );
};

export default ContentImage;
export { ContentImageProps };
