import React, { isValidElement } from "react";
import classNames from "classnames";

import Clone from "../../0-electrons/Clone/Clone";
import DynamicTag from "./../../0-electrons/DynamicTag/index";
import Button from "../../1-atoms/Button/Button";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";

import { getClassName } from "../../0-electrons/css";
import * as css from "./Teaser.module.scss";

interface TeaserChildrenProps {
  /** The Teasers children */
  children?: React.ReactNode;
  /** The Teasers Headline */
  headline?: string;
  /** The tag to use for the headline */
  headlineTag?: keyof JSX.IntrinsicElements;
  /** The Teasers href */
  href?: string;
  /** The Teasers image */
  image?: React.ReactElement;
  /** The Teasers number */
  number?: number;
  /** The Teasers textLink */
  textLink?: string;
}

export interface TeaserProps extends TeaserChildrenProps {
  /** className to add */
  className?: string | undefined;
}

export const Teaser: React.FC<TeaserProps> = ({
  children,
  className,
  headline,
  headlineTag = "h3",
  href,
  image,
  number,
  textLink,
}: TeaserProps) => {
  const hasImg = isValidElement(image);
  const hasReactElementChildren =
    isValidElement(children) && children.type !== React.Fragment;

  return (
    <div
      className={classNames(getClassName(css, "teaser"), className, {
        [getClassName(css, "teaser--img") as string]: hasImg,
      })}
    >
      {hasImg ? (
        <Clone
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          node={image!}
          className={classNames(
            getClassName(css, "teaser__circle"),
            getClassName(css, "teaser__circle--img")
          )}
        />
      ) : (
        <div className={getClassName(css, "teaser__circle")}>{number}</div>
      )}

      <DynamicTag
        className={getClassName(css, "teaser__headline")}
        tag={headlineTag}
      >
        {headline}
      </DynamicTag>

      {hasReactElementChildren ? (
        children
      ) : (
        <TypographyCopy
          className={getClassName(css, "teaser__copy")}
          size="small"
          color="grey"
        >
          {children}
        </TypographyCopy>
      )}

      {textLink ? (
        <Button
          className={getClassName(css, "teaser__link")}
          href={href}
          tag="a"
        >
          {textLink}
        </Button>
      ) : null}
    </div>
  );
};

export default Teaser;
