// TODO: add Typescript Typings
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";

import ContentImage, {
  ContentImageWrapper,
} from "../components/2-molecules/ContentImage/ContentImage";

interface ContentfulImageContentProps {
  caption?: string;
  image: IGatsbyImageData;
  title: string;
}

export interface ContentfulImageProps {
  content: ContentfulImageContentProps;
}

const ContentfulImage: React.FC<ContentfulImageProps> = ({
  content,
}: ContentfulImageProps) => {
  return (
    <ContentImageWrapper>
      <ContentImage caption={_get(content, "caption", null)}>
        <GatsbyImage
          image={_get(content, "image.gatsbyImageData", null)}
          alt={_get(content, "title")}
        />
      </ContentImage>
    </ContentImageWrapper>
  );
};

export default ContentfulImage;

export const query = graphql`
  fragment FragmentContentfulImage on ContentfulComponentImage {
    id
    title
    caption
    image {
      id
      gatsbyImageData(placeholder: BLURRED, width: 730)
    }
    internal {
      type
    }
  }
`;
