import React, { useState } from "react";

import { default as _get } from "lodash/get";
import { default as _has } from "lodash/has";

import { getClassName } from "../../0-electrons/css";

import Button from "../../1-atoms/Button/Button";
import VideoPlayer from "../../1-atoms/VideoPlayer/VideoPlayer";

import * as css from "./Video.module.scss";

export interface VideoProps {}

export const Video: React.FC<VideoProps> = ({
  caption,
  poster,
  src,
  onClick,
}) => {
  const [player, setPlayer] = useState(null);
  const [useFacade, setUseFacade] = useState(true);
  const [playerState, setPlayerState] = useState("facade");
  const getButtonLabel = state => {
    switch (state) {
      case "playing":
        return "pause";
      case "pause":
      case "ready":
      case "facade":
        return "play";
      default:
        return "loading";
    }
  };
  const playerAvailable = _has(player, "play");
  const buttonDisabled = !playerAvailable && useFacade === false;
  const buttonVisible = useFacade;

  return (
    <>
      <figure className={getClassName(css, "Video")}>
        <div className={getClassName(css, "Video__wrapper")}>
          <VideoPlayer
            controls
            autoplay
            facade={useFacade}
            poster={poster}
            src={src}
            onReady={plyr => {
              setPlayer(plyr);
              setPlayerState("ready");
            }}
          />
          {buttonVisible ? (
            <div
              className={getClassName(css, "Video__play")}
              onClick={
                playerAvailable
                  ? () => {
                      if (playerState === "playing") {
                        player.pause();
                        setPlayerState("pause");
                      } else {
                        player.play();
                        setPlayerState("playing");
                      }
                    }
                  : () => {
                      setPlayerState("loading");
                      setUseFacade(false);

                      if (typeof onClick === "function") {
                        onClick();
                      }
                    }
              }
            >
              <Button
                icon="play"
                iconOnly
                type="primary"
                disabled={buttonDisabled}
              />
            </div>
          ) : null}
        </div>

        {typeof caption === "string" && caption.length > 0 ? (
          <figcaption className={getClassName(css, "Video__caption")}>
            {caption}
          </figcaption>
        ) : null}
      </figure>
    </>
  );
};

export default Video;
