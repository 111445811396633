import React from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";
import RichText from "../RichText/RichText";

import * as css from "./ImageText.module.scss";

export interface ImageTextProps {
  /** The children to use for copy/text */
  children: React.ReactNode;
  /** If the image text order should be flipped */
  flip?: boolean;
  /** Headline to use (will be above the image on mobile) */
  headline?: string;
  /** Headline Tag to use */
  headlineTag?: string;
  /** HTML id attribute of the component */
  id?: string;
  /** The Element/Node to use as the slot */
  slot: React.ReactElement;
}

const ImageText: React.FC<ImageTextProps> = ({
  children,
  flip = false,
  headline,
  headlineTag = "h2",
  id,
  slot = null,
}: ImageTextProps) => {
  return (
    <div
      id={typeof id === "string" && id.length > 0 ? id : undefined}
      className={classNames(getClassName(css, "ImageText"), {
        [getClassName(css, "ImageText--flip") as string]: flip === true,
      })}
    >
      {headline ? (
        <TypographyHeadline
          size="h2"
          tag={headlineTag}
          className={getClassName(css, "ImageText__headline")}
          disableSpacing
        >
          {headline}
        </TypographyHeadline>
      ) : null}

      {/* Slot for Images*/}
      {slot !== null ? (
        <Clone node={slot} className={getClassName(css, "ImageText__image")} />
      ) : null}

      <RichText
        className={classNames(getClassName(css, "ImageText__copy"))}
        fluid
      >
        {useChildren(children)}
      </RichText>
    </div>
  );
};

function useChildren(children) {
  // wrap children with p when it is a string
  if (typeof children === "string") {
    return <p>{children}</p>;
  }

  return children;
}

export default ImageText;
