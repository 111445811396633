import React from "react";

import { default as _get } from "lodash/get";

import ContentfulImage from "./ContentfulImage";
import ContentfulImageText from "./ContentfulImageText";
import ContentfulLocationFinder from "./ContentfulLocationFinder";
import ContentfulRichText from "./ContentfulRichText";
import ContentfulSliderCards from "./ContentfulSliderCards";
import ContentfulStageVideo from "./ContentfulStageVideo";
import ContentfulTeaserBig from "./ContentfulTeaserBig";
import ContentfulTeaserGroup from "./ContentfulTeaserGroup";
import ContentfulVideo from "./ContentfulVideo";

export function parseSections(sections, locations) {
  let shouldFlip = false;

  return Array.isArray(sections)
    ? sections.map(section => {
        const componentType = _get(section, "internal.type", false);
        if (componentType === false) return null;

        let component;

        switch (section.internal.type) {
          case "ContentfulComponentImageText":
            component = (
              <ContentfulImageText content={section} flip={shouldFlip} />
            );
            shouldFlip = !shouldFlip;
            break;

          case "ContentfulComponentImage":
            return <ContentfulImage content={section} />;
            break;

          case "ContentfulComponentLocationFinder":
            return (
              <ContentfulLocationFinder
                content={section}
                locations={locations}
              />
            );
            break;

          case "ContentfulComponentRichText":
            component = <ContentfulRichText content={section} />;
            break;

          case "ContentfulComponentCardSlider":
            component = <ContentfulSliderCards content={section} />;
            break;

          case "ContentfulComponentTeaserBig":
            component = <ContentfulTeaserBig content={section} />;
            break;

          case "ContentfulComponentTeaserGroup":
            component = <ContentfulTeaserGroup content={section} />;
            break;

          case "ContentfulComponentStageVideo":
            component = <ContentfulStageVideo content={section} />;
            break;

          case "ContentfulComponentVideoContent":
            component = <ContentfulVideo content={section} />;
            break;

          default:
            component = (
              <section>
                {section.title} ({section.internal.type})
              </section>
            );
        }

        return component;
      })
    : null;
}

/**
 * Map Contentful GraphQL nodes to posts
 * transforms/maps node: { id, title ...} to { id, title, ...}
 *
 * @param {array} nodes Array of GraphQL nodes
 * @returns Array of posts
 */
// TODO: rename to mapNodesToEntries
export function mapNodesToPosts(nodes: Array) {
  // TODO: TS Typings
  return nodes.map(node => Object.values(node)[0]);
}
