// TODO: scroll snap polyfill check?
// TODO: offset left for larger devices: perhaps with "fake" slide as the scroll offset is not calculated with scroll-padding in mind
import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import _get from "lodash/get";
import _uniqueId from "lodash/uniqueId";

import { getClassName } from "../../0-electrons/css";
import { Button } from "../../1-atoms/Button/Button";

import { useIntersectionObserver } from "../../0-electrons/hooks/use-intersection-observer";
import { useResizeObserver } from "../../0-electrons/hooks/use-resize-observer";

import * as css from "./Slider.module.scss";

export type SliderTheme = "default" | "skin" | "white";
export type TCardSize = "default" | "s";
export interface SliderProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
  /** Name of the Slider, will be used for IDs. Fallback to "Slider" */
  name?: string;
  theme?: SliderTheme;
  cardSize?: TCardSize;
}

const SLIDER_ID_DEFAULT = "Slider";

// clones element and adds intersection observer for slide
const renderSlide = ({
  slide,
  id,
  onVisibilityChange = () => console.log("onVisibilityChange"),
  // onCallback = entries =>
  //   entries.forEach(e => console.log("InterSectionCallback", { e })),
}) => {
  const slideRef = useRef(null);

  // Pass an optional callback to perform side effects instead:
  const inView = useIntersectionObserver({
    ref: slideRef,
    options: {
      triggerOnce: false,
      threshold: 1,
      // rootMargin: "-50% 0px -50% 0px",
      rootMargin: "50% 0px 50% 0px",
    },
    // callback: onCallback,
    // callback: entries =>
    //   entries.forEach(e => console.log("InterSectionCallback", { e })),
  });

  useEffect(() => {
    onVisibilityChange({ inView });
    // if (inView) {
    //   // => Perform any side effect with it!
    // }
  }, [inView]);

  const clonedElement = React.cloneElement(slide, {
    // add (unique) ID if not already set
    id: id,
    className: getClassName(css, "Slider__card"),
    ref: slideRef,
  });

  return clonedElement;
};

const initSlidesState = ({ slideCount, prefix = SLIDER_ID_DEFAULT }) =>
  Array.from(Array(slideCount)).map((item, index) => ({
    id: `${prefix}_${index}`,
    visible: false,
  }));

const renderSlides = (children, slides, hasSlides, setSlides) => {
  // const [slides, setSlides] = useState();

  return React.Children.map(children, (child, index) =>
    renderSlide({
      slide: child,
      id: _get(slides, `[${index}].id`, undefined),
      // onCallback: e =>
      //   console.log("outer callback", { index, slides, setSlides, e }),
      onVisibilityChange: ({ inView }) => {
        // do nothing if the slides aren't set up until now
        if (!hasSlides) return;

        const slidesCurrent = [...slides];
        slidesCurrent[index] = { ...slidesCurrent[index], visible: inView };
        setSlides(slidesCurrent);
      },
    })
  );
};

export const Slider: React.FC<SliderProps> = ({
  children,
  className,
  id,
  theme = "default",
  cardSize = "default",
}: SliderProps) => {
  // basic initial variables/constants
  const slideCount = children.length;

  // Refs
  const sliderRef = useRef(null);
  const sliderTrackRef = useRef(null);

  // state
  const [currentSlide, setCurrentSlide] = useState(0);
  // add (unique) ID if not already set
  const [sliderSettings, setSliderSettings] = useState({
    id: undefined,
    prefix: undefined,
  });
  const [slides, setSlides] = useState([]);
  const [space, setSpace] = useState({ available: Infinity, needed: Infinity }); // used to check if the slider can center
  const [canCenter, setCanCenter] = useState(false);

  const hasSlides = Array.isArray(slides) && slides.length > 0;
  let slideFirst = hasSlides ? slides[0] : undefined;
  let slideLast = hasSlides ? slides[slides.length - 1] : undefined;

  // const sliderInitialized = currentSlide !== -1;

  // Functions
  const updateSpace = () => {
    setSpace({
      available: sliderTrackRef.current
        ? sliderTrackRef.current.getBoundingClientRect().width
        : undefined,
      needed: hasSlides
        ? document
            .getElementById(slides[slides.length - 1].id)
            ?.getBoundingClientRect().right -
          document.getElementById(slides[0].id)?.getBoundingClientRect().left
        : Infinity,
    });
  };

  const getFirstActive = slides =>
    slides.findIndex(slide => _get(slide, "visible", false) === true);

  const goTo = goTo => {
    const slideChildren = sliderTrackRef.current.children;

    const activeSlide = slideChildren[currentSlide];
    const goToSlide = slideChildren[goTo];

    sliderTrackRef.current.scrollTo({
      top: 0,
      left: 0,
      left:
        sliderTrackRef.current.scrollLeft +
        goToSlide.getBoundingClientRect().left -
        activeSlide.getBoundingClientRect().left,
      behavior: "smooth",
    });
  };

  const goToPrev = () => {
    const firstActive = getFirstActive(slides);
    goTo(firstActive - 1);
  };

  const goToNext = () => {
    const firstActive = getFirstActive(slides);
    goTo(firstActive + 1);
  };

  // initial setup
  useEffect(() => {
    const sliderId =
      typeof id === "string" && id.length ? id : _uniqueId(SLIDER_ID_DEFAULT);
    const sliderPrefix = `${sliderId}`;
    setSliderSettings(() => {
      return {
        id: sliderId,
        prefix: sliderPrefix,
      };
    });
    // sliderPrefix = `${sliderId}_`;

    setSlides(() => initSlidesState({ slideCount, prefix: sliderPrefix }));
  }, []);

  // after slider setup
  useEffect(() => {
    setCanCenter(
      () =>
        typeof space.available === "number" &&
        typeof space.needed === "number" &&
        space.available > space.needed + 30
    );
  }, [space]);

  // effect for initial loading
  // useEffect(() => {
  //   console.log("useEffect", sliderRef.current);

  //   setCurrentSlide(0);
  // }, [slider]);

  // effect for slide visibility changes
  // also usable to debug visibility state
  useEffect(() => {
    const firstActiveSlide = getFirstActive(slides);

    // if (firstActiveSlide > -1) setCurrentSlide(firstActiveSlide);
    setCurrentSlide(() => firstActiveSlide);
  }, [slides]);

  useEffect(() => {
    // console.log("current slide changed to", currentSlide);
  }, [currentSlide]);

  // React to resizes
  useResizeObserver({
    ref: sliderRef,
    callback: entries => {
      for (const entry of entries) {
        const event = new CustomEvent("resize");
        entry.target.dispatchEvent(event);
      }
    },
  });
  useEffect(() => {
    if (hasSlides && sliderRef.current) {
      sliderRef.current.addEventListener("resize", () => {
        updateSpace();
      });
    }
  }, [sliderRef, hasSlides]);
  // console.log({ children });
  return (
    <div
      className={classNames(getClassName(css, "Slider"), {
        [getClassName(css, `Slider--theme-${theme}`) as string]:
          typeof theme === "string" && theme !== "default",
        [getClassName(css, `Slider--card-size-${cardSize}`) as string]:
          typeof cardSize === "string" && cardSize !== "default",
        [getClassName(css, "Slider--disable-scroll") as string]: canCenter,
      })}
      ref={sliderRef}
    >
      <div
        ref={sliderTrackRef}
        id={sliderSettings.id}
        // data-snap-slider={sliderSettings.id}
        // data-snap-slider-align="center"
        className={classNames(
          getClassName(css, "Slider__track"),
          getClassName(css, "Slider__scrollfix"), // TODO: check if needed and/or if it works
          {
            [className as string]:
              typeof className === "string" && className.length > 0,
          }
        )}
      >
        {renderSlides(children, slides, hasSlides, setSlides)}
      </div>
      <div
        className={classNames(getClassName(css, "Slider__nav"))}
        // data-snap-slider-nav={sliderSettings.id}
      >
        <Button
          animation="left"
          icon="directionLeft"
          iconOnly
          shadow
          invert
          onClick={goToPrev}
          tag="button"
          type="secondary-fill"
          // data-snap-slider-goto="prev"
          className={classNames(
            getClassName(css, "Slider__button"),
            _get(slideFirst, "visible", false) === true
              ? getClassName(css, "Slider__button--hide")
              : getClassName(css, "Slider__button--show")
          )}
        />
        <Button
          icon="directionRight"
          iconOnly
          shadow
          invert
          onClick={goToNext}
          tag="button"
          type="secondary-fill"
          // data-snap-slider-goto="next"
          className={classNames(
            getClassName(css, "Slider__button"),
            hasSlides && _get(slideLast, "visible", false) === true
              ? getClassName(css, "Slider__button--hide")
              : getClassName(css, "Slider__button--show")
          )}
        />
      </div>
    </div>
  );
};

export default Slider;
