// TODO: add Typescript Typings
import React from "react";

import { graphql } from "gatsby";

import { default as _get } from "lodash/get";

import TeaserGroup from "../components/3-organisms/TeaserGroup/TeaserGroup";
import ContentfulRichText from "./ContentfulRichText";

import { stringToLowerCase } from "./converter";

const ContentfulTeaserGroup = ({ content }) => {
  const teaserItems = content.items.map((item, index) => {
    return {
      number: index + 1,
      headline: item.title,
      headlineTag: stringToLowerCase(item.titleTag),
      children: (
        <ContentfulRichText
          content={{
            body: item.body.raw,
          }}
          fluid
        />
      ),
    };
  });

  return (
    <TeaserGroup
      id={_get(content, "idSlug", undefined)}
      headline={_get(content, "title", undefined)}
      // TODO: fix typing
      // @ts-expect-error not assignable to type 'keyof IntrinsicElements | undefined'
      headlineTag={stringToLowerCase(_get(content, "titleTag", undefined))}
      content={teaserItems}
    />
  );
};

export default ContentfulTeaserGroup;

export const query = graphql`
  fragment FragmentContentfulTeaserGroup on ContentfulComponentTeaserGroup {
    id
    idSlug
    title
    titleTag
    items {
      title
      titleTag
      body {
        raw
      }
    }
    internal {
      type
    }
  }
`;
