// TODO: add Typescript Typings
// TODO: check flipping feature (should be handled in helper.tsx)
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";

import ContentImage from "../components/2-molecules/ContentImage/ContentImage";
import ImageText from "../components/2-molecules/ImageText/ImageText";

import ContentfulRichText from "./ContentfulRichText";

const ContentfulImageText = ({ content, flip }) => {
  const image = {
    id: _get(content, "idSlug", undefined),
    caption: _get(content, "image.caption", undefined),
    src: _get(content, "image.image.gatsbyImageData", undefined),
    alt: _get(content, "image.title", undefined),
  };
  const copy = {
    title: _get(content, "title", undefined),
    titleTag: _get(content, "titleTag", "h3"),
    body: _get(content, "body.raw", undefined),
  };

  return (
    <ImageText
      id={image.id}
      slot={
        <ContentImage caption={image.caption}>
          <GatsbyImage image={image.src} alt={image.alt} />
        </ContentImage>
      }
      flip={flip}
    >
      <ContentfulRichText
        fluid
        content={{
          title: copy.title,
          titleTag: copy.titleTag,
          body: copy.body,
        }}
      />
    </ImageText>
  );
};

export default ContentfulImageText;

export const query = graphql`
  fragment FragmentContentfulImageText on ContentfulComponentImageText {
    id
    title
    titleTag
    body {
      raw
    }
    image {
      title
      caption
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: BLURRED)
      }
    }
    internal {
      type
    }
  }
`;
