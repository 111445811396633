// extracted by mini-css-extract-plugin
export var Card = "Card-module--Card--11m-S";
export var CardThemePrimary = "Card-module--Card--theme-primary--h6M7B";
export var CardThemeSkin = "Card-module--Card--theme-skin--osb3m";
export var Card__category = "Card-module--Card__category--WTX+w";
export var Card__content = "Card-module--Card__content--JD6+p";
export var Card__copy = "Card-module--Card__copy--cT3WZ";
export var Card__cta = "Card-module--Card__cta--DSCd1";
export var Card__ctaAutoWidth = "Card-module--Card__cta--auto-width--f0CzU";
export var Card__headline = "Card-module--Card__headline--j0IE1";
export var Card__image = "Card-module--Card__image--6NWOa";