// see @url https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
import { useEffect, useState, RefObject } from "react";

/**
 * Hook parameters.
 */
interface UseResizeObserverProperties {
  /**
   * Ref object from `useRef`.
   */
  ref?: RefObject<Element> | null;

  /**
   * DOM element. E.g. from `querySelector()`
   */
  element?: Element | null | undefined;

  /**
   * Callback to fire when the observed component or Element
   * is resized.
   */
  callback: (entries: ResizeObserverEntry[]) => void;
}

const IS_BROWSER = typeof window !== "undefined";

/**
 * Watch for the Resize of a React component or
 * Element.
 */
export const useResizeObserver = ({
  ref,
  element,
  callback,
}: UseResizeObserverProperties) => {
  const [resizeObs] = useState(() =>
    IS_BROWSER ? new ResizeObserver(callback) : undefined
  );

  useEffect(() => {
    if (!resizeObs) return;

    let domNode;

    if (ref) domNode = ref.current;
    else if (element) domNode = element;

    if (domNode) resizeObs.observe(domNode);

    return () => {
      resizeObs.disconnect();
    };
  }, [ref, resizeObs, element]);
};
