// TODO: Unit tests
import React from "react";

import { Link, graphql } from "gatsby";

import { default as _get } from "lodash/get";
import { default as _isEmpty } from "lodash/isEmpty";
import { default as _trim } from "lodash/trim";

import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import RichText from "../components/2-molecules/RichText/RichText";

import { stringToLowerCase } from "./converter";
import { getRootUri, isExternalUri } from "../helper/uri";

// Escaped new line to break tag
// see @url https://stackoverflow.com/a/60160501
const newLineToBreak = input =>
  typeof input === "string"
    ? input.split("\n").map((item, idx) => {
        // only if not last
        return idx < input.split("\n").length - 1 ? (
          <React.Fragment key={idx}>
            {item}
            <br />
          </React.Fragment>
        ) : (
          item
        );
      })
    : input;

export const getLinkByReference = (hyperlink, references) =>
  references.find(reference => {
    const hyperlinkId = _get(hyperlink, "data.target.sys.id", null);
    const referenceId = _get(reference, "contentful_id", null);

    return typeof hyperlinkId === "string" && typeof referenceId === "string"
      ? hyperlinkId === referenceId
      : false;
  });

export const richTextOptions = ({ references, footnotes, idSlug }) => {
  return {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { slug } = getLinkByReference(node, references);

        return <Link to={getRootUri(slug)}>{children}</Link>;
      },

      [INLINES.HYPERLINK]: (node, children) => {
        const uri = _get(node, "data.uri", null);
        const isExternal = isExternalUri(uri);
        const label = _get(node, "content[0].value", children);

        return (
          <a
            href={uri}
            rel={isExternal ? "nofollow noreferrer noopener" : undefined}
            target={isExternal ? "_blank" : undefined}
          >
            {label}
          </a>
        );
      },
    },

    renderText: text => {
      /**
       * Parse and collect footnotes
       */
      const getFootnoteSlug = (type, number, withHash = true) => {
        return `${withHash ? "#" : ""}${idSlug}_${type}-${number}`;
      };

      // find footnotes marked in text
      const footnoteRegex = new RegExp(/\[\^[0-9]{1,}:.+?\]/, "g");
      const footnotesFound = text.match(footnoteRegex);

      // collect and build footnote array
      if (Array.isArray(footnotesFound) && Array.isArray(footnotes)) {
        let textWithMarkers = text;
        // transform to matches with capture groups and add markers in text
        footnotesFound.forEach(found => {
          // transform
          const foundWithGroups = found.match(/\[\^[0-9]{1,}:(.+?)\]/);

          // push footnotes to outer array
          const footnoteNumber = footnotes.length + 1;
          footnotes.push({
            id: getFootnoteSlug("note", footnoteNumber, false),
            href: getFootnoteSlug("ref", footnoteNumber),
            children: _trim(foundWithGroups[1]),
          });

          // add markers
          textWithMarkers = textWithMarkers.replace(
            foundWithGroups[0],
            `___{NOTE_${footnoteNumber}}___`
          );

          return foundWithGroups;
        });

        // adjust parse text to render
        // transforms string to array of strings/ReactNodes
        const textWithMarkersSplit = textWithMarkers
          .split("___")
          .filter(part => part.length > 0)
          .map(part => {
            const shouldReplace = part.match(/\{NOTE_([0-9]{1,})\}/);

            if (Array.isArray(shouldReplace)) {
              const footnoteNumber = parseInt(shouldReplace[1]);

              return (
                <a
                  href={getFootnoteSlug("note", footnoteNumber)}
                  id={getFootnoteSlug("ref", footnoteNumber, false)}
                  role="doc-noteref"
                />
              );
            }

            return part;
          });

        return textWithMarkersSplit;
      }

      return newLineToBreak(text);
    },
  };
};

interface ContentfulRichtextBodyProps {
  raw: string;
}
interface ContentfulRichTextContentProps {
  idSlug?: string;
  title?: string;
  titleTag?: string;
  body: ContentfulRichtextBodyProps;
}

interface ContentfulRichTextProps {
  content: ContentfulRichTextContentProps;
  fluid?: boolean;
}

const ContentfulRichText: React.FC<ContentfulRichTextProps> = ({
  content,
  fluid = false,
}: ContentfulRichTextProps) => {
  const idSlug = _get(content, "idSlug", undefined);
  const titleTag = _get(content, "titleTag", undefined);
  const fontSize = _get(content, "fontSize", undefined);
  const body =
    _get(content, "body.raw", undefined) || _get(content, "body", undefined);

  const bodyObject = JSON.parse(body);

  // parse list children and remove all paragraph
  // nodes around text nodes in lists
  const bodyObjectCleaned = {
    ...bodyObject,
    content: bodyObject.content.map(node => {
      // ...bodyObject,
      // content: bodyObject.content.map(node => {
      if (
        node.nodeType === BLOCKS.UL_LIST ||
        node.nodeType === BLOCKS.OL_LIST
      ) {
        return {
          ...node,
          content: transformParagraphToText(node.content),
        };
      }

      return node;
    }),
  };

  // parse foot note references and add them as list at the end
  const footnotes = [];
  function parseFootnotes(content: any) {
    return content.map(node => {
      if (node.nodeType === "text") {
        return node;
      }

      // not a text -> use recursive function to walk down the tree
      return {
        ...node,
        content: parseFootnotes(node.content),
      };
    });
  }

  const bodyObjectWithFootNotes = {
    ...bodyObjectCleaned,
    content: parseFootnotes(bodyObjectCleaned.content),
  };

  // drop empty paragraphs/text nodes
  function dropEmptyChildren(content: any) {
    return content.filter(node => {
      const childContent = _get(node, "content", []);
      // no children
      // --> drop
      if (childContent.length === 0) return false;

      const singleChildContent = childContent.length == 1;
      const singleChildEmpty =
        _get(childContent, "[0].nodeType", null) === "text" &&
        _trim(_get(childContent, "[0].value", "")) === "";

      // single child with nodeType text and empty value
      // --> drop
      if (singleChildContent && singleChildEmpty) {
        return false;
      }

      return true;
    });
  }

  const bodyObjectWithDroppedEmptyChildren = {
    ...bodyObjectWithFootNotes,
    content: dropEmptyChildren(bodyObjectWithFootNotes.content),
  };

  const references = _get(content, "body.references", []);

  const contentParsed =
    typeof body === "string"
      ? documentToReactComponents(
          bodyObjectWithDroppedEmptyChildren,
          richTextOptions({ references, footnotes, idSlug })
        )
      : undefined;

  return (
    <RichText
      id={idSlug}
      headline={_get(content, "title", undefined)}
      tag={stringToLowerCase(titleTag)}
      fluid={fluid}
      fontSize={fontSize}
      spacing="bottom-l"
      footnotes={
        Array.isArray(footnotes) && footnotes.length > 0 ? footnotes : undefined
      }
    >
      {contentParsed}
    </RichText>
  );
};

/**
 * Tranforms RichText pagraph nodes to text nodes recursively
 * TODO: TS Typings
 *
 * @param {object} content The content/node structure to parse.
 * @returns The parsed content/node structure.
 */
function transformParagraphToText(content: any) {
  // use forEach and an empty array here as one paragraph node
  // will be transformed to multiple text nodes
  const mappedContent = [];
  content.forEach(node => {
    // not a paragraph -> use recursive function to walk down the tree
    if (node.nodeType !== BLOCKS.PARAGRAPH) {
      mappedContent.push({
        ...node,
        content: transformParagraphToText(node.content),
      });
      return;
    }

    const nodeContent = _get(node, "content", []);
    nodeContent.forEach(childContent => mappedContent.push(childContent));
    return;
  });

  return mappedContent;
}

export default ContentfulRichText;

export const query = graphql`
  fragment FragmentContentfulRichText on ContentfulComponentRichText {
    id
    idSlug
    title
    titleTag
    internal {
      type
    }
    fontSize
    body {
      raw
      references {
        ... on ContentfulEntry {
          ... on ContentfulBlogPost {
            slug
            contentful_id
          }
          ... on ContentfulCategoryPage {
            slug
            contentful_id
          }
        }
      }
    }
  }
`;
