// extracted by mini-css-extract-plugin
export var Stage = "Stage-module--Stage--obVfJ";
export var Stage__claim = "Stage-module--Stage__claim--uTbDB";
export var Stage__container = "Stage-module--Stage__container--pQTmi";
export var Stage__copy = "Stage-module--Stage__copy--hkLZx";
export var Stage__heading = "Stage-module--Stage__heading--G3-MW";
export var Stage__headline = "Stage-module--Stage__headline--J2KAz";
export var Stage__homepageContainer = "Stage-module--Stage__homepageContainer--SGNbe";
export var Stage__homepageContainerHidden = "Stage-module--Stage__homepageContainer--hidden--d9bEW";
export var Stage__image = "Stage-module--Stage__image--hIosT";
export var Stage__textContainer = "Stage-module--Stage__textContainer---PeD7";
export var Stage__video = "Stage-module--Stage__video--Fpvl6";
export var hide = "Stage-module--hide--FEULF";